export const PITCH_DECK = 'https://docsend.com/view/6tnfzez7ajzaqn47'
export const EXECUTIVE_SUMMARY = 'https://docsend.com/view/9hb3pvhcrcwpb3gf'
export const PODCASTS = 'https://docsend.com/view/nesbgtfi4x7jg46x'
export const DEMO_VIDEOS = 'https://docsend.com/view/uhwwkvcprbxfcj88'
export const WHITE_PAPER = 'https://docsend.com/view/zjaxy5xhpswzi94e'
export const LITE_PAPER = 'https://docsend.com/view/mw8sp8gcjxv7jx5r'
export const DOCS = 'https://docsend.com/view/82629acby9fuu9fw'
export const TOKENOMICS = 'https://docsend.com/view/bgnav9ws4xc7ebg5'
export const FINANCIAL_MODEL = 'https://docsend.com/view/cy33nv73uddarrjf'
export const CAP_TABLE = 'https://docsend.com/view/35jrs6zu8y282kdw'
export const LEGAL_DOCS = 'https://docsend.com/view/s/m682vc5yi3uipi5h'
export const GROWTH_STRATEGY = 'https://docsend.com/view/u6jrtsd58jd9b8dr'
export const MEDIA_MENTIONS = 'https://docsend.com/view/ddfh5gafpt77qqd8'
export const SCHEDULE_A_CALL = 'https://docsend.com/view/mkffsbvv6gnpnmap'
export const CONTACT_US = 'https://docsend.com/view/sex5mr2cqkzkuqvg'
export const BORING_DOCSEND = 'https://docsend.com/view/s/ykfspw336e2c8yrs'
